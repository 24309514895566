/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, {useEffect} from 'react';
import {Animate, AuthContext, Message, Row} from 'components/lib';
import {useContext} from 'react';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';

export function Signin(props) {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      return; 
    }

    // handle case when user is redirected back from MFA
    const hash = location.hash;
    const accessToken = hash.match(/access_token=([^&]*)/)?.[1];
    const authError = hash.match(/error=([^&]*)/)?.[1];
    if (accessToken || authError) {
      authContext.checkSessionCb(authError, accessToken).then();
      if (authError && location.pathname === '/signin') {
        authContext.lock.show();
      }

      return;
    }

    if (localStorage.getItem('isAuthenticated') === 'true' && location.pathname === '/signin') {
      authContext.lock.checkSession({
        redirectUri: window.location.origin + '/signin',
      }, async (err, authResult) => {
        await authContext.checkSessionCb(err, authResult ? authResult.accessToken : null);
        if (err && location.pathname === '/signin') {
          authContext.lock.show();
        }
      })
    } else {
      authContext.lock.show();
    }
  }, [authContext.lock, authContext.checkSessionCb, location.pathname, location.hash, authContext]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      const savedPath = localStorage.getItem('savedPath');
      const savedQuery = localStorage.getItem('savedQuery');

      // Clear saved path/query from localStorage
      localStorage.removeItem('savedPath');
      localStorage.removeItem('savedQuery');

      if (savedPath) {
        navigate(savedPath + (savedQuery || ''));
      } else {
        if (!authContext.user?.verified) {
          navigate('/signup/verify');
        } else {
          navigate('/dashboard');
        }
      }
    }
  }, [authContext.isAuthenticated, authContext.user, navigate]);

  return (
    <Animate type='pop'>
      <Row title='Provider Sign In' color='black'>
        {searchParams.get("reason") === 'account_disabled' ?
          <Message
            type={'error'}
            title={"Account Disabled"}
            text={"Your account has been disabled"}
            button={false}
          /> : <></>
        }
        <div id="auth0-lock-container" className="mx-auto mb-5" />
      </Row>
    </Animate>
  );
}
