/***
 *
 *   CREATE/MANAGE EXPERIENCE
 *
 *
 **********/

import React, {Fragment, useContext, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {AuthContext, CreateExperienceForm, Card, Animate, useAPI, Loader} from 'components/lib';
import {useState} from 'react';

export function ManageExperience(props) {
  const {id} = useParams();

  // context
  const authContext = useContext(AuthContext);

  // fetch
  const {data: experienceData, loading: experienceDataLoading} = useAPI(id ? `/api/experience/${id}` : null);
  const {data: categories, loading: categoriesLoading} = useAPI('/api/user/categories');
  const {data: metroArea, loading: metroAreaLoading} = useAPI('/api/user/area');
  const [readonly, setReadonly] = useState(false);
  const [isUpdatesPendingChild, setIsUpdatesPendingChild] = useState(false);

  const categoriesList = useMemo(() => {
    let arr = [];
    if (categories && categories.length > 0 && !categoriesLoading) {
      categories.forEach(({disabled, label, id}) => {
        if (!disabled) {
          arr.push({value: id, label});
        }
      });
      return arr;
    } else return [];
  }, [categories, categoriesLoading]);

  const metroAreaList = useMemo(() => {
    let arr = [];
    if (metroArea && metroArea.length > 0 && !metroAreaLoading) {
      metroArea.forEach(({disabled, label, id}) => {
        if (!disabled) {
          arr.push({value: id, label});
        }
      });
      return arr;
    } else return [];
  }, [metroArea, metroAreaLoading]);

  const experienceOptions = useMemo(() => {
    if (experienceData) {
      if (!experienceData.original_experience_id && experienceData.status === 'updates_pending') {
        // the original experience, only accesible for read
        setReadonly(true)
      } else if (experienceData.original_experience_id && experienceData.status === 'updates_pending') {
        // the dublicate experience, can be edited
        setIsUpdatesPendingChild(true)
      }
    }

    if (experienceData?.options) {
      experienceData.options = experienceData.options.map((option, idx) => {
        if (!option.sort_order && option.sort_order != 0) {
          option.sort_order = idx;
        }
        return option
      })
      experienceData.options.sort((a, b) => a.sort_order - b.sort_order);
      return experienceData.options.map(({title, description, price, quantity, id, original_option_id, sort_order}) => ({
        title: {
          label: 'Title',
          type: 'text',
          required: true,
          options: 'options',
          value: title,
          max: 65,
        },
        description: {
          label: 'Description',
          type: 'textarea',
          required: true,
          value: description,
          max: 1500,
        },
        price: {
          label: 'Option Price',
          type: 'number',
          value: price,
          tip: "Please input the Price (USD) for this Option or select Pricing Available Upon Request.",
          required: !experienceData?.price_upon_request,
          disabled: !!experienceData?.price_upon_request,
        },
        quantity: {
          label: 'Option Quantity available',
          type: 'number',
          required: true,
          value: quantity,
        },
        id: {
          type: 'hidden',
          value: id,
        },
        original_option_id: {
          type: 'hidden',
          value: original_option_id,
        },
        sort_order: {
          type: 'hidden',
          value: sort_order,
        }
      }));
    }
    return [
      {
        title: {
          label: 'Title',
          type: 'text',
          required: true,
          options: 'options',
          max: 65,
        },
        description: {
          label: 'Description',
          type: 'textarea',
          required: true,
          max: 1500,
        },
        price: {
          label: 'Option Price',
          type: 'number',
          tip: "Please input the Price (USD) for this Option or select Pricing Available Upon Request.",
          required: !experienceData?.price_upon_request,
          disabled: !!experienceData?.price_upon_request,
        },
        quantity: {
          label: 'Option Quantity available',
          type: 'number',
          required: true,
        },
        sort_order: {
          type: 'hidden',
          value: 0,
        }
      },
    ];
  }, [experienceData]);

  return (
    <Fragment>
      <Animate>
        <Card
          title={`${experienceData ? 'Edit' : 'Create new'} experience`}
          loading={experienceDataLoading || categoriesLoading || metroAreaLoading}
        >
          {metroArea?.length && categories?.length && ((id && experienceData) || !id) && ((id && experienceOptions) || !id) ? (
            <CreateExperienceForm
              buttonText="Save"
              url={experienceData ? `/api/experience/${id}` : "/api/experience"}
              method={experienceData ? "PATCH" : "POST"}
              redirect={experienceData?.status === 'updates_pending' ? "/account/experience/updates-pending" : "/account/experience"}
              status={experienceData?.status ?? 'draft'}
              readonly={readonly}
              experienceStatus={experienceData?.status ?? 'draft'}
              isUpdatesPendingChild={isUpdatesPendingChild}
              id={experienceData?.id}
              inputs={{
                title: {
                  label: 'Title',
                  type: 'text',
                  required: true,
                  tip: `This is the title of the experience. Example: "Private Meet and Greet with James Bond"`,
                  value: experienceData?.title ?? '',
                  min: 1,
                  max: 65,
                },
                address: {
                  label: 'Location',
                  type: 'autocomplete',
                  tip: "The exact address/venue of the Experience",
                  required: true,
                  value: experienceData?.address ?? '',
                },
                area_id: {
                  label: 'Metro Area',
                  type: 'select',
                  tip: "Please select the Closest Metro Area of the Experience",
                  options: metroAreaList,
                  required: true,
                  default: experienceData?.area_id ?? null,
                },
                ongoing: {
                  label: 'Ongoing event',
                  type: 'switch',
                  required: false,
                  default: id && !experienceData?.date,
                  value: id && !experienceData?.date,
                },
                date: {
                  label: 'Date',
                  type: 'date',
                  tip: "Select the Date of the Experience OR select Ongoing event to allow Concierge to inquire for booking.",
                  required: false,
                  value: experienceData?.date ?? new Date().toISOString(),
                  disabled: !experienceData?.date
                },
                special_requirements: {
                  label: 'Special Requirements',
                  type: 'textarea',
                  value: experienceData?.special_requirements ?? '',
                  max: 512,
                  tip: "Age restrictions, physical capabilities, specific prerequisites, and other special requirements for the Experience may be shared here.",
                },
                emails: {
                  label: 'Visibility',
                  type: 'text',
                  tip: "Most events are available for all Concierges on VIPSRV. If availability for this Experience is limited to specific users, add the email address associated with their VIPSRV account here.",
                  placeholder:
                    `If it's public – leave field empty. To invite more than one user, separate the emails with a comma`,
                  value: experienceData?.emails ?? '',
                },
                photo: {
                  label: 'Photo',
                  type: 'file',
                  tip: "Add a photo for this Experience. Accepted formats are .jpg, .jpeg, .gif and .png, maximum size of the photo should not be larger 5MB.",
                  required: true,
                  max: 1,
                  default: experienceData?.photoSignedUrl ?? '',
                  value: experienceData?.photoSignedUrl ? [experienceData?.photoSignedUrl]: '',
                  objectKey: experienceData?.photo,

                  metadata: {acceptSingleFile: true}
                },
                schedule_timestamp: {
                  label: 'Go-Live Date',
                  type: 'dateTime',
                  required: true,
                  value: experienceData?.schedule_timestamp ?? '',
                  tip: "The date and time (local timezone) that the Experience should be visible/available for booking on VIPSRV.",
                },
                categories: {
                  label: 'Categories',
                  type: 'multiselect',
                  options: categoriesList,
                  required: true,
                  default: experienceData?.categories || undefined,
                  tip: "Please select all of the applicable categories for this Experience.",
                },
                price_upon_request: {
                  label: "Pricing Available Upon on Request",
                  type: 'switch',
                  required: false,
                  default: experienceData?.price_upon_request ?? false,
                  value: experienceData?.price_upon_request ?? false,
                  tip: '',
                },
                ticket_interval: {
                  label: 'Amount of tickets in a set',
                  type: 'number',
                  required: false,
                  min: 1,
                  value: experienceData?.ticket_interval,
                  tip: 'Minimum amount is 1 ticket per set',
                },
                options: {
                  label: 'Options',
                  type: 'options',
                  max: 3,
                  values: experienceOptions,
                },
              }}
              callback={res => {
                authContext.update({name: res.data.data.name, avatar: res.data.data.avatar});
              }}
            />
          ) : <Loader />}
        </Card>
      </Animate>
    </Fragment>
  );
}
