/* eslint-disable no-async-without-await/no-async-without-await */
/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import {useState, useEffect, createContext} from 'react';
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import {useContext} from 'react';
import Auth0Lock from 'auth0-lock';
import {useCallback} from 'react';

// auth context
export const AuthContext = createContext();

const permissions = require('./permissions');
const customStyles = `
 .auth0-lock.auth0-lock .auth0-lock-header {
    display: none !important;
  }
 
  .auth0-lock.auth0-lock     .auth0-lock-center {
    padding-top: 0 !important;
  }
`;
const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds

export function AuthProvider(props) {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const setUserAuthenticatedStorage = useCallback((auth) => {
    localStorage.setItem('isAuthenticated', auth);
  }, [])
  const hasUserAuthenticatedStorage = useCallback(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  }, [])

  const fetchSetCurrentUser = useCallback(async (authToken) => {
    try {
      const userResp = await axios.get('/api/auth/me', {headers: {Authorization: `Bearer ${authToken}`}})
      setUser(userResp.data)

    } catch (e) {
      console.error(e)
    }
  }, [])

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const [lock] = useState(() => new Auth0Lock(
    process.env.REACT_APP_AUTH0_CLIENT_ID_CONTROLPANEL,
    process.env.REACT_APP_AUTH0_DOMAIN,
    {
      auth: {
        redirect: true,
        responseType: 'token',
        sso: false,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        params: {
          scope: process.env.REACT_APP_AUTH0_TOKEN_SCOPE
        },
        autoParseHash: false,
      },
      redirectUri: window.location.origin + '/signin',
      allowShowPassword: true,
      allowSignUp: false,
      allowForgotPassword: true,
      allowAutocomplete: false,
      allowPasswordAutocomplete: false,
      rememberLastLogin: false,
      avatar: false,
      container: 'auth0-lock-container',
      closable: false,
      theme: {
        primaryColor: '#22C55E'
      }
    }
  ));

  const signout = useCallback((disabled = false, logoutUrl = null) => {
    setAuthToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem('savedPath');
    localStorage.removeItem('savedQuery');
    setUserAuthenticatedStorage(false);
    
    if (disabled) {
      lock.logout({returnTo: `${window.location.origin}/signin?reason=account_disabled`})
    } else if (!logoutUrl) {
      lock.logout({returnTo: `${window.location.origin}`})
    }

    if (logoutUrl) {
      lock.logout({returnTo: logoutUrl})
    }
  }, [lock, setUserAuthenticatedStorage]);

  // Update last activity time on user interaction
  const updateLastActivity = () => {
    setLastActivity(Date.now());
  };



  const checkSessionCb = useCallback(async (error, accessToken) => {
    if (error) {
      setIsAuthenticated(false);
      setIsAuthLoading(false)
      setUserAuthenticatedStorage(false)
      return;
    }
    if (accessToken) {
      setAuthToken(accessToken);
      await fetchSetCurrentUser(accessToken);
      setIsAuthenticated(true);
      setIsAuthLoading(false)
      setUserAuthenticatedStorage(true)
    }
  }, [fetchSetCurrentUser, setUserAuthenticatedStorage])

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = customStyles;
    document.head.appendChild(styleElement);


    // Set up Auth0Lock event listeners
    lock.on('authenticated', async (authResult) => {
      if (authResult && authResult.accessToken) {
        setAuthToken(authResult.accessToken);
        await fetchSetCurrentUser(authResult.accessToken);
        setIsAuthenticated(true);
        setIsAuthLoading(false)
        setUserAuthenticatedStorage(true)
      }
    });

    lock.on('authorization_error', (error) => {
      setIsAuthenticated(false);
      setIsAuthLoading(false)
      setUserAuthenticatedStorage(false)
    });

    // Cleanup
    return () => {
      lock.hide();
      document.head.removeChild(styleElement);
      lock.removeAllListeners();
    };
  }, [fetchSetCurrentUser, lock, setUserAuthenticatedStorage]);

  useEffect(() => {
    if (isAuthenticated) {
      const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];
      events.forEach(event => {
        window.addEventListener(event, updateLastActivity);
      });

      // Check for inactivity every minute
      const intervalId = setInterval(() => {
        const currentTime = Date.now();
        if (currentTime - lastActivity >= INACTIVITY_TIMEOUT) {
          signout()
        }
      }, 30000); 

      // Cleanup
      return () => {
        events.forEach(event => {
          window.removeEventListener(event, updateLastActivity);
        });
        clearInterval(intervalId);
      };
    }
  }, [isAuthenticated, lastActivity, signout]);

  function updateEmailVerificationToken(token) {
    localStorage.setItem('emailVerificationToken', token);
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key]
        }
        else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey]
          }
        }
        else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider value={{
      updateEmailVerificationToken: updateEmailVerificationToken,
      update: update,
      setAuthToken,
      signout,
      // Export for use in components, i.e. sign in component
      lock,
      hasUserAuthenticatedStorage,
      user,
      isAuthLoading,
      isAuthenticated,
      switchAccount: () => {},
      permission: permissions[user?.permission],
      checkSessionCb
    }}
      {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState(authContext.user);
  useEffect(() => {
    setUser(authContext.user)
  }, [authContext.user]);

  const path = window.location.pathname;
  const query = window.location.search;

  if (user) {
    const userHasPermission = Object.prototype.toString.call(props.permission) === '[object Array]'
      ? props.permission.some(p => permissions[user.permission][p]) : permissions[user.permission][props.permission];

    if (userHasPermission) {
      if (user.verified) {
        if (path === '/signup/verify')
          return <Navigate to='/dashboard' />
      }
      else {
        if (path !== '/account/profile' && path !== '/signup/verify')
          return <Navigate to='/signup/verify' />;
      }

      return props.children;
    }
  }

  localStorage.setItem('savedPath', path);
  localStorage.setItem('savedQuery', query);
  if (!authContext.hasUserAuthenticatedStorage()) {
    authContext.signout(false, `${window.location.origin}/signin`);
  } else {

    return <Navigate to='/signin' />;
  }
}
